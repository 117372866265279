<template>
    <b-row>
      <b-col lg="12" class="mb-2">
        <vue-editor v-model="crud.form.observations_internal"></vue-editor>          
      </b-col>
          
      <b-col lg="12">            
        <b-button variant="dark" class="pull-right" size="sm" @click="save()">Guardar</b-button>
      </b-col>
    </b-row>
</template>
<script>
    import serviceAPI from './../services'
    import Error from '@/handler/error'
    import Session from '@/handler/session'
    import Profiles from '@/config/profiles'
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper'        
    import { VueEditor } from "vue2-editor";

    export default {
      components: {                
        VueEditor,
      },      
      props: [
        'staff',
        'staffID',
      ],
        data() {
          return {
            crud: {
              form: {                        
                id: 0,                        
                observations_internal: ''
              }
            },
            componentKey: 0,
          }
        },
        watch: {        
          staff: function (newQuestion, oldQuestion) {            
            this.load()
          }
        },        
        methods: {
            load() {              
              if(this.staff){                
                this.crud.form.id = this.staff.id,
                this.crud.form.observations_internal = this.staff.observations_internal,
                
                this.forceRerender()        
              } else {
                this.crud.form.id = this.staffID                    
                this.crud.form.observations_internal = ''
              }
            },
                      
            save() {
              let loader = this.$loading.show();
              var result = serviceAPI.setObservacionesInternas({
                staff_id: this.crud.form.id,
                observations_internal: this.crud.form.observations_internal,
              });

              result.then((response) => {                
                loader.hide()                
                this.$awn.success("Notas guardadas con éxito");
              })
              .catch(error => {
                loader.hide()
                this.$awn.alert(Error.showError(error));
              })
            },
             
            forceRerender() {
                this.componentKey += 1;  
            },             
        }
    }
</script>