import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtener() {
    var url = ConfigAPI.baseURL + "staff" + Session.getToken();
    return instance.get(url);
  },
  mostrar(id) {
    var url = ConfigAPI.baseURL + "staff/" + id + Session.getToken();
    return instance.get(url);
  },
  agregar(data, image1) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData
    
    var url = ConfigAPI.baseURL + "staff" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editar(data, image1) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData    

    var url = ConfigAPI.baseURL + "staff/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },
  eliminar(id) {
    var url = ConfigAPI.baseURL + "staff/"+ id + Session.getToken();
    return instance.delete(url);
  },
  filtrarStaff(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "staff/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },  
  obtenerRoles() {
    var url = ConfigAPI.baseURL + "roles" + Session.getToken();
    return instance.get(url);
  },
  crearUsuario(data) {
    var params = {
      email: data.email,
      password: data.password,
      roles: data.roles,
    }
    var url = ConfigAPI.baseURL + "staff/createUser/" + data.staff_id + Session.getToken();
    return instance.post(url,params);
  },
  editarUsuarioRoles(data) {
    var params = {
      roles: data.roles,
    }
    var url = ConfigAPI.baseURL + "staff/editUserRoles/" + data.staff_id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarUsuario(id) {
    var url = ConfigAPI.baseURL + "staff/destroyUser/"+ id + Session.getToken();
    return instance.delete(url);
  },
  setObservacionesInternas(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "staff/update/observationsInternals" + Session.getToken();
    return instance.post(url,params);
  },  
  setConfiguracionesDefault(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "staff/update/configurationsDefault" + Session.getToken();
    return instance.post(url,params);
  },    
}

export default servicesAPI;
