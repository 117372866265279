<template>
    <b-row>    
      <b-col md="12" v-if="showSales || showDeposit || showFinance">
        <b-alert varian="primary" show>
          Las siguientes configuraciones, tienen <b>mayor prioridad</b> sobre las configuradas en los puntos de ventas.
        </b-alert>
      </b-col>
      <b-col md="12" v-if="showSales">
        <FindObject render="search"
                    description="Punto de venta por defecto para imputar operaciones. Si queda vacío, queda sin efecto"
                    type="erpPointsSales" 
                    display="full_identification"
                    @select-object="loadPointsSales($event)" 
                    :valueID="crud.form.points_sales_id"
                    :key="crud.form.points_sales_id"/>         
      </b-col>                        
      <b-col md="6" v-if="showDeposit">
        <FindObject render="search"
                    description="Depósito por defecto para descontar productos cuando el usuario realice la operación. Si queda vacío, queda sin efecto"
                    type="deposits"                            
                    @select-object="loadDeposit($event)" 
                    :valueID="crud.form.deposit_id"
                    :showAllRegister="true"                    
                    :key="crud.form.deposit_id"/>                         
      </b-col>
      <b-col md="6" v-if="showFinance">
        <FindObject render="search"
                    description="Caja por defecto para recibir o pagar cuando el usuario realice la operación. Si queda vacío, queda sin efecto"
                    type="cash" 
                    @select-object="loadCash($event)" 
                    :valueID="crud.form.cash_id"
                    :showAllRegister="true"
                    :key="crud.form.cash_id"
                    :where="conditionCashND"/>
      </b-col>                  
      <b-col lg="12">            
        <b-button variant="dark" class="pull-right" size="sm" @click="save()">Guardar</b-button>
      </b-col>
    </b-row>
</template>
<script>
    import serviceAPI from './../services'
    import Error from '@/handler/error'
    import Session from '@/handler/session'
    import Profiles from '@/config/profiles'
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper'        
    import { VueEditor } from "vue2-editor";
    import FindObject from '@/components/inc/find/findObject'       

    export default {
      components: {                
        VueEditor,
        FindObject
      },      
      props: [
        'staff',
        'staffID',
      ],
        data() {
          return {
            crud: {
              form: {                        
                id: 0,                                        
                cash: null,
                cash_id: 0,                
                deposit: null,
                deposit_id: 0,                
                points_sales: null,
                points_sales_id: 0,
              }
            },            
          }
        },
        watch: {        
          staff: function (newQuestion, oldQuestion) {            
            this.load()
          }
        },        
        computed: {
          // WHERE SELECT
          conditionCashND(){        
            let condition = []
            if(this.crud.form.points_sales) {
              condition = [{field: 'nd', condition: this.crud.form.points_sales.nd}];
            } 
            return condition
          }, 

          // MODULES
          showDeposit() {
            var status = false
            var modules = Session.getSession().auth.user.permissions.modules        
            modules.forEach(element => {
              if(element.id == Modules.DEPOSITOS) {                             
                status = true                       
              }
            })        
            return status
          },          
          showFinance() {
            var status = false
            var modules = Session.getSession().auth.user.permissions.modules        
            modules.forEach(element => {
              if(element.id == Modules.FINANZAS) {                             
                status = true                       
              }
            })        
            return status
          },    
          showSales() {
            var status = false
            var modules = Session.getSession().auth.user.permissions.modules        
            modules.forEach(element => {
              if(element.id == Modules.VENTAS) {                             
                status = true                       
              }
            })        
            return status
          },                              
        },        
        methods: {
            load() {              
              if(this.staff){                
                this.crud.form.id = this.staff.id
                this.crud.form.cash_id = this.staff.cash_id
                this.crud.form.cash = this.staff.cash
                this.crud.form.deposit_id = this.staff.deposit_id
                this.crud.form.deposit = this.staff.deposit
                this.crud.form.points_sales_id = this.staff.points_sales_id
                this.crud.form.points_sales = this.staff.point_sale             
              } else {
                this.crud.form.id = this.staffID                    
                this.crud.form.cash = null
                this.crud.form.cash_id = 0
                this.crud.form.deposit = null
                this.crud.form.deposit_id = 0
                this.crud.form.points_sales = null
                this.crud.form.points_sales_id = 0
              }
            },
                      
            save() {
              let loader = this.$loading.show();
              var result = serviceAPI.setConfiguracionesDefault({
                staff_id: this.crud.form.id,
                cash_id: this.crud.form.cash_id,
                deposit_id: this.crud.form.deposit_id,
                points_sales_id: this.crud.form.points_sales_id
              });

              result.then((response) => {                
                loader.hide()                
                this.$awn.success("Notas guardadas con éxito");
              })
              .catch(error => {
                loader.hide()
                this.$awn.alert(Error.showError(error));
              })
            },
             
            loadPointsSales (object) {
              if(object){
                this.crud.form.points_sales = object
                this.crud.form.points_sales_id = object.id    
                
                if(this.crud.form.cash && this.crud.form.cash.nd != object.nd) {
                  this.crud.form.cash = null
                  this.crud.form.cash_id = 0                  
                }
              } else {
                this.crud.form.points_sales = null
                this.crud.form.points_sales_id = 0
              }              
            },             
            loadCash (object) {        
              if(object){
                this.crud.form.cash = object
                this.crud.form.cash_id = object.id        
              } else {
                this.crud.form.cash = null
                this.crud.form.cash_id = 0
              }
            },     
            loadDeposit (object) {        
              if(object){
                this.crud.form.deposit = object
                this.crud.form.deposit_id = object.id                   
              } else {
                this.crud.form.deposit = null
                this.crud.form.deposit_id = 0
              }
            } 
        }
    }
</script>